import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { throwError, Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  carServices : Array<any> = [];
  service : any = false;

  constructor(
    private title : Title,
    private _http : HttpClient ,
    ) {}


  localUrl : string = 'https://www.asservices.in/api/v1'; 
  liveUrl : string = 'https://www.asservices.in/api/v1';

  apiUrl : string = (window.location.origin.match('localhost') !== null ? this.localUrl : this.liveUrl);

  _response(response : any){

    if(response.code !== 200){
      throw new Error(response.data)
    }

    return response.data

  }

  setTitle(title){
    this.title.setTitle(title + ' | AS Services');
  }

  _hendleError(error: any){
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = error.message || 'Oops! Something went wrong, Please try again.';
    }
    return errorMessage;
  }

  __post(url : string,data:any){


    return this._http.post(`${this.apiUrl}${url}`,data,{headers : this._getHeaders()})
    .pipe(
      map((response : any) => {
        return this._response(response)
      }),
      catchError((error : any) =>{
        console.log(error)
        return throwError(this._hendleError(error))
      })
    )
  }


  getToken(){
    return localStorage.getItem('X-ASServices-Authorization');
  }

  _getHeaders(){
    var token = this.getToken();
    return new HttpHeaders({
      'X-ASServices-Authorization' : (token ? token : 'unAuth')
    });
  }

  showSuccess(message) {
    alert(message);
  }

  showError(message) {
    alert(message);
  }
  
  showWarning(message) {
    alert(message);
  }

}
