import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { DataService } from '../shared/data.service';
import Testimonial from '../portfolio/Testimonial.modal';



declare var  PIXELSIGNS :any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})


export class HomeComponent implements OnInit, AfterViewInit {

  @ViewChild('modelOfFiverr',{static : true}) modelOfFiverr : ElementRef;

  statistic = {
    projects : 0,
    clients : 0,
    developers : 0,
    languages : 0
  }

  testimonials : Array<Testimonial> = [];
  currentIndex: number = 0;

  constructor(
    private dataService : DataService
  ) { }

  ngOnInit() {

    this.dataService.setTitle('Home')

    this._setStatistics();
    this._getTestimonials();
  }

  ngAfterViewInit(){
    setTimeout(() => {
      PIXELSIGNS.initialize.swiperSlider();
    },1000);


    const d = new Date();
    if( !window.localStorage.getItem(`${d.getDate()}-${d.getMonth()}-${d.getFullYear()}`) ){
    
      setTimeout(() => {
        const el : HTMLElement = this.modelOfFiverr.nativeElement;
        el.click();
      },2000)

    }



  }


  handleCloseModel = () => {
    const d = new Date();
    window.localStorage.setItem(`${d.getDate()}-${d.getMonth()}-${d.getFullYear()}`,'DONE');
  }

  _setStatistics = () => {
    this.dataService.__post('/statistics/get',{})
    .subscribe(
      data => {
        this.statistic = data;
      },
      error => {

      }
    )
  }

  _getTestimonials = () => {
    this.dataService.__post('/testimonials/get',{})
    .subscribe(
      data => {
        data.map( (testimonial) => {
          this.testimonials.push(new Testimonial(testimonial))
        })
      },
      error => {

      }
    )
  }

  showNextSlide(): void {
    this.currentIndex = (this.currentIndex + 1) % this.testimonials.length;
  }

  showPrevSlide(): void {
    this.currentIndex = (this.currentIndex - 1 + this.testimonials.length) % this.testimonials.length;
  }

}
